import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'

async function getEmbed(key, { url, apiKey }) {
  const response = await axios.get('https://api.embedly.com/1/oembed', {
    params: {
      url,
      key: apiKey,
    },
  })
  return response.data
}

export function Embedly({ url, apiKey }) {
  const { data, isLoading } = useQuery(['embedly', { url, apiKey }], getEmbed)
  return <div dangerouslySetInnerHTML={{ __html: data?.html }}></div>
}
